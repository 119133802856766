import React from 'react';
import { graphql } from 'gatsby';

import Layout from '@/components/organisms/Layout';
import Seo from '@/components/atoms/Seo';
import TermsOfSalesContainer from '@/containers/TermsOfSales/TermsOfSalesContainer';

import { LegalPagesQuery } from '../../../graphql-types';

interface Props {
  data: LegalPagesQuery;
}

export const query = graphql`
  query TermsOfSalesIndustryLegalPages {
    prismicLegalPages {
      data {
        meta_title_terms
        meta_description_terms
        ...TermsOfSales
      }
    }
  }
`;

const TermsOfSales = ({ data }: Props): JSX.Element => {
  const { meta_title_terms, meta_description_terms } =
    data?.prismicLegalPages?.data || {};
  return (
    <Layout invertNav type="usine">
      <Seo
        title={meta_title_terms || 'title'}
        description={meta_description_terms || 'description'}
      />
      <TermsOfSalesContainer data={data} type="usine" />
    </Layout>
  );
};

export default TermsOfSales;
