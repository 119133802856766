import React from 'react';
import { RichText } from 'prismic-reactjs';
import TermsOfSalesContent from '@/containers/TermsOfSales/TermsOfSalesContent';

import useHtmlSerializer from '@/prismic/useHtmlSerializer';

import { LegalTemplateQuery } from '../../../graphql-types';

interface Props {
  data: LegalTemplateQuery;
  type?: string;
}

const TermsOfSalesContainer = ({ data }: Props): JSX.Element => {
  const htmlSerializer = useHtmlSerializer();
  const { terms_of_sales_page } = data?.prismicLegalPages?.data || {};

  const TermsOfSalesProps = {
    termsOfSales: (
      <RichText
        render={terms_of_sales_page?.raw}
        htmlSerializer={htmlSerializer}
      />
    ),
  };
  return (
    <>
      <TermsOfSalesContent {...TermsOfSalesProps} />
    </>
  );
};

export default TermsOfSalesContainer;
